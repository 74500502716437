import { navigate } from 'gatsby';

export const Routes = (val, callback) => {
  const back = () => {
    typeof window !== 'undefined' && window.history.back();
  };
  const data = JSON.parse(localStorage.getItem('data'));
  if (localStorage.getItem('token') === null) {
    navigate('/auth');
  } else if (val === 'superAdmin') {
    if (data.role_id > 50 && data.role_id < 100) {
      navigate('/myYard');
    } else if (data.role_id > 40 && data.role_id < 50) {
      navigate('/yard/home');
    }
    else if (data.role_id === 199) {
      navigate('/equipment-drop-off');
    } else {
      callback(true);
    }
  } else if (val === 'manager') {
    if (data.role_id === 999 || data.role_id === 800) {
      navigate('/client');
    } else if (data.role_id > 40 && data.role_id < 50) {
      navigate('/yard/home');
    }
    else if (data.role_id === 199) {
      navigate('/equipment-drop-off');
    } else {
      callback(true);
    }
  } else if (val === 'yard') {
    if (data.role_id === 999 || data.role_id === 800) {
      navigate('/client');
    } else if (data.role_id > 50 && data.role_id < 100) {
      {
        if(data.role_id ===51 || data.role_id===52 || data.role_id===99){
          return callback(true);
        }
      }
      navigate('/myYard');
    }
    else if (data.role_id === 199) {
      navigate('/equipment-drop-off');
    }
    else {
      callback(true);
    }
  } else if (val === 'common') {
    if (data.role_id > 40 && data.role_id < 50) {
      navigate('/yard/home');
    }
    else if (data.role_id === 199) {
      navigate('/equipment-drop-off');
    } else {
      // else if(data.role_id>50&&data.role_id<100){
      //   navigate ('/myYard');
      // }else if(data.role_id===999){
      //   navigate ('/client');
      // }
      callback(true);
    }
  }
  else if (val === 'welcome') {
    if (data.role_id > 40 && data.role_id < 50) {
      navigate('/yard/home');
    }
    else if (data.role_id > 50 && data.role_id < 100) {
      navigate('/myYard');
    } else {
      callback(true);
    }
  }
};
