import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Tag,
  Tooltip,
  message,
  Button,
  Divider,
  Form,
  Badge,
  Dropdown,
  Menu,
} from 'antd';
import { EyeOutlined, DownOutlined } from '@ant-design/icons';
import { navigate } from 'gatsby';
import moment from 'moment';
import { connect } from 'react-redux';

import AppLayout from '../../components/layout';
import ServiceForm from './comp/Form';
import {
  addServices,
  getServiceList,
  updateStatus,
  updateServices,
  deleteServices
} from '../../actions/serviceAction';
import ServiceView from './comp/ServiceView';
import './service.css';
import { Routes } from '../../utils/route';
import { Spin } from 'antd';
import { NumToStr } from '../../utils/helper'; 
const Service = (props) => {
  const [state, setState] = useState({
    users: [],
    isShowService: false,
    selectedServiceToShow: null,
  });
  const [form] = Form.useForm();
  const [id,setId]=useState(null)
  const [price, setPrice] = useState('');
  const [formEdit,setFormEdit]=useState(false)
  const [tax, setTax] = useState(0);
  const [serviceGroup, setServiceGroup] = useState([]);
  const [selectedServiceGroup, setSelectedServiceGroup] = useState(null);
  const [service, setService] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [validateFrom, setValidateFrom] = useState(moment(Date.now()));
  const [validateTo, setValidateTo] = useState(moment());
  const [remark, setRemark] = useState('');
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(true);
  // const {serviceList}=API
  const add_years = (dt, n) => {
    return new Date(dt.setFullYear(dt.getFullYear() + n));
  };
  useEffect(() => {
    const callback = (val) => {
      if (val === true) {
        setLoading(false);
        props.getServiceList();
        getDataFromLocalStorage();
      }
    };
    Routes('manager', callback);
  }, []);

  const filterData = (val) => {
    var resArr = [];
    let newArr = [];
    val.forEach(function(item) {
      var i = resArr.filter(
        (x) => x.value == item.service_group_name.param_value
      );
      if (i.length <= 0) {
        resArr.push({
          ...item,
          name: item.service_group_name.param_description,
          value: item.service_group_name.param_value,
        });
      }
    });
    resArr.forEach((res, i) => {
      let arr = [];
      val.map((x) => {
        if (x.service_group_name.param_value === res.value) {
          arr.push(x);
        }
      });
      newArr.push({ ...res, child: arr });
    });
    setLoad(false);
    setState({ ...state, users: newArr });
  };

  useEffect(
    () => {
      filterData(props.list);
    },
    [props.list]
  );

  useEffect(
    () => {
      const _users = [...users];
      _users.push(props.newUserData);
      setState({ ...state, users: _users });
    },
    [props.newUserData]
  );

  useEffect(
    () => {
      const _users = [...users];
      if (props.updateUserData !== undefined) {
        _users.filter((item, i) => {
          if (
            parseInt(props.updateUserData.user_id) === parseInt(item.user_id)
          ) {
            _users[i] = props.updateUserData;
          }
        });
      }

      setState({ ...state, users: _users });
    },
    [props.updateUserData]
  );

  useEffect(
    () => {
      const _users = [...users];
      _users &&
        _users.map((item) => {
          if (item.user_id === props.userId) {
            item.status = props.user_status;
          }
        });
      setState({ ...state, users: _users });
    },
    [props.userId, props.user_status]
  );

  const getDataFromLocalStorage = async () => {
    const data =
      (await typeof window) !== 'undefined' ? localStorage.getItem('data') : '';
    if (data) {
      let jsonParse = JSON.parse(data);

      let services = [];
      let serviceGroup = [];
      jsonParse.TabParams.map((item) => {
        if (
          item.param_key === 'service_type' &&
          item.param_name !== 'Consumable_Type'
        ) {
          services.push(item);
        } else if (item.param_key === 'service_group') {
          serviceGroup.push(item);
        }
      });
      services.sort((a,b) => (a.param_description > b.param_description) ? 1 : ((b.param_description> a.param_description) ? -1 : 0))
      serviceGroup.sort((a,b) => (a.param_description > b.param_description) ? 1 : ((b.param_description> a.param_description) ? -1 : 0))
      setService(services);
      setServiceGroup(serviceGroup);
    }
  };

  const setServices = (e) => {
    const data = localStorage.getItem('data');
    if (data) {
      let jsonParse = JSON.parse(data);
      let services = [];
      jsonParse.TabParams.map((item) => {
        if (item.param_key === 'service_type') {
          if (e !== 2040 && item.param_name !== 'Consumable_Type') {
            services.push(item);
          } else if (e === 2040 && item.param_name === 'Consumable_Type') {
            services.push(item);
          }
        }
      });
      setService(services);
    }
  };

  const showService = (serviceInfo) => {
    setId(serviceInfo.id)
    setState({
      ...state,
      isShowService: true,
      selectedServiceToShow: serviceInfo,
    });
  };
  const { users, isShowService, selectedServiceToShow } = state;
  const handleChange = (v) => {
    switch (v[0].name[0]) {
      case 'service':
        setSelectedService(v[0].value);
        break;
      case 'Price':
        setPrice(v[0].value);
        break;
      case 'Tax':
        setTax(v[0].value);
        break;
      case 'Valid from':
        v[0].value && setValidateFrom(v[0].value);
        break;
      case 'Valid To':
        v[0].value && setValidateTo(v[0].value);
        break;
      case 'Remark':
        setRemark(v[0].value);
        break;
      default:
        break;
    }
  };

  const handleServiceGroupChange = (v) => {
    setSelectedServiceGroup(v);
    setServices(v);
    form.setFieldsValue({service:""})
  };

  const validate = async () => {
    if (selectedServiceGroup === null) {
      message.success({ content: `Please Select Service Group`, duration: 2 });
      return false;
    } else if (selectedService === null) {
      message.success({ content: `Please Select Service`, duration: 2 });
      return false;
    } else if (price === '') {
      message.success({ content: `Please Select Price`, duration: 2 });
      return false;
    } else {
      return true;
    }
  };
  const cancelEdit=()=>{
    setFormEdit(false)
    form.resetFields();
    setSelectedServiceGroup(null);
  }
  const deleteService=()=>{
    props.deleteServices(id);
    setState({
      ...state,
      isShowService: false,
    });
  }

  const updateService=async()=>{
    const isValid = await validate();
    if (isValid) {
      let param = {
        id:id,
        price: price,
        tax: parseFloat(tax),
        remark: remark,
      };
      props.updateServices(param);
      setFormEdit(false)
      form.resetFields();
      setSelectedServiceGroup(null);
      setState({
        ...state,
        isShowService: false,
      });
    }
    
  }
  const addService = async () => {
    const dt = new Date();
    const isValid = await validate();
    if (isValid) {
      let param = {
        service_group_id: selectedServiceGroup,
        service_id: selectedService,
        name: '',
        price: price,
        tax: parseFloat(tax),
        price_valid_from: validateFrom.format(),
        price_valid_till: moment(add_years(dt, 15)).format(),
        remark: remark,
      };
      const callback=(cb)=>{
        if(cb){
          form.resetFields();
          setSelectedServiceGroup(null);
        }
      }
      props.addServices(param,callback);
    }
  };
  const menu = (
    <Menu>
      <Menu.Item>Action 1</Menu.Item>
      <Menu.Item>Action 2</Menu.Item>
    </Menu>
  );
  const expandedRowRender = (children) => {
    const columns = [
      {
        title: 'SERVICE',
        dataIndex: 'email',
        render: (_, render) => (
          <span className="text-capitalize">{render.service_name.param_description}</span>
        ),
        sorter: {
          compare: (a, b) => {
            a = a.service_name.param_description.toLowerCase();
            b = b.service_name.param_description.toLowerCase();
            return a > b ? -1 : b > a ? 1 : 0;
          },
        },
      },
      {
        title: 'PRICE',
        dataIndex: 'price',
        render: (_, render) => <span>${NumToStr(render.price||0,0)}</span>,
        sorter: {
          compare: (a, b) => {
            a = a.price;
            b = b.price;
            return a > b ? -1 : b > a ? 1 : 0;
          },
        },
      },
      {
        title: 'TAX',
        dataIndex: 'tax',
        render: (_, render) => <span>{render.tax}</span>,
        sorter: {
          compare: (a, b) => {
            a = a.tax;
            b = b.tax;
            return a > b ? -1 : b > a ? 1 : 0;
          },
        },
      },
      {
        title: 'PRICE VALID FROM',
        dataIndex: 'p_v_f',
        render: (_, render) => (
          <span>{moment(render.price_valid_from).format('MM/DD/YYYY')}</span>
        ),
        sorter: {
          compare: (a, b) => {
            a = moment(a.price_valid_from).format('MM/DD/YYYY');
            b = moment(b.price_valid_from).format('MM/DD/YYYY');
            return a > b ? -1 : b > a ? 1 : 0;
          },
        },
      },
      // {
      //   title: 'PRICE VALID TILL',
      //   dataIndex: 'p_v_t',
      //   render: (_, render) => (
      //     <span>{moment(render.price_valid_till).format('MM/DD/YYYY')}</span>
      //   ),
      //   sorter: {
      //     compare: (a, b) => {
      //       a = moment(a.price_valid_till).format('MM/DD/YYYY');
      //       b = moment(b.price_valid_till).format('MM/DD/YYYY');
      //       return a > b ? -1 : b > a ? 1 : 0;
      //     },
      //   },
      // },
      {
        title: 'STATUS',
        dataIndex: 'status',
        render: (status) => (
          <Tag
          className="text-capitalize"
            color={status === 0 ? 'red' : 'cyan'}
          >
            {status === 0 ? 'Inactive' : 'Active'}
          </Tag>
        ),
        sorter: {
          compare: (a, b) => {
            a = a.status;
            b = b.status;
            return a > b ? -1 : b > a ? 1 : 0;
          },
        },
      },

      {
        title: '',
        dataIndex: 'actions',
        render: (_, elm) => (
          <div className="text-right">
            <Tooltip title="View">
              <Button
                type="primary"
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => {
                  showService(elm);
                }}
                size="small"
              />
            </Tooltip>
          </div>
        ),
      },
    ];

    const data = children.child ? children.child : [];

    return (
      <Table
        style={{ margin: '5px 0px' }}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    );
  };

  const tableColumns = [
    // {
    // 	title: 'No.',
    //     dataIndex: 'index',
    // 	render: (_,render) => (
    // 		<span>{render.index}</span>
    // 	),
    // 	sorter: {
    // 		compare: (a, b) => {
    // 			a = a.user_id;
    // 			b = b.user_id;
    // 			return a > b ? -1 : b > a ? 1 : 0;
    // 		},
    // 	},
    // 	align:'center'
    // },
    {
      title: 'SERVICE GROUP',
      dataIndex: 'service_group_name',
      render: (_, render) => (
        <span className="text-capitalize">
          <b>{render.service_group_name.param_description}</b>
        </span>
      ),

      sorter: {
        compare: (a, b) => {
          a = a.service_group_name.param_description.toLowerCase();
          b = b.service_group_name.param_description.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
  ];
  const editServiceData=()=>{
    setFormEdit(true)
    const editData=selectedServiceToShow
    setSelectedServiceGroup(editData.service_group_id)
    form.setFieldsValue({service:editData.service_id,
                         Price: editData.price,
                        Tax:editData.tax,
                        Remark:editData.remark});
                        setSelectedService(editData.service_id)
                        setTax(editData.tax)
                        setPrice( editData.price)
                        setRemark(editData.remark)
                        setState({
                          ...state,
                          isShowService: false,
                        });
  }

  if (!loading) {
    return (
      <AppLayout>
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h3 style={{ marginBottom: 10 }}>EQUIPMENT MASTER</h3>
        </div>
        <Divider />
        <Card bodyStyle={{ padding: '0px' }}>
          <Table
            className="components-table-demo-nested"
            expandable={{ expandedRowRender }}
            loading={props.loading}
            columns={tableColumns}
            dataSource={users}
            rowKey="id"
          />
        </Card>
        <Divider />
        <h3>{formEdit? "Update Equipment":"Create Equipment"}</h3>
        <div>
          <ServiceForm
            handleChange={(e) => handleChange(e)}
            form={form}
            price={price}
            selectedService={selectedService}
            tax={tax}
            onCancelEdit={()=>cancelEdit()}
            formEdit={formEdit}
            remark={remark}
            onSubmit={() => formEdit?updateService():addService()}
            loading={props.loading}
            selectedServiceGroup={selectedServiceGroup}
            handleServiceGroupChange={handleServiceGroupChange}
            serviceGroup={serviceGroup}
            services={service}
          />
        </div>
        <ServiceView
          data={selectedServiceToShow}
          visible={isShowService}
          onClickEdit={()=>editServiceData()}
          onClickDelete={()=>deleteService()}
          close={() => {
            setState({
              ...state,
              isShowService: false,
              selectedServiceToShow: null,
            });
          }}
        />
      </AppLayout>
    );
  } else {
    return (
      <div className="spinner-main">
        <Spin />
      </div>
    );
  }
};
const mapStateToProps = (state) => ({
  loading: state.service.loading,
  user_status: state.user.user_status,
  newUserData: state.user.newUserData,
  updateUserData: state.user.updateUserData,
  userId: state.user.userId,
  list: state.service.list,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addServices: (data) => dispatch(addServices(data)),
    updateServices: (data) => dispatch(updateServices(data)),
    getServiceList: () => dispatch(getServiceList()),
    updateStatus: (data) => dispatch(updateStatus(data)),
    deleteServices:(data)=>dispatch(deleteServices(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Service);
